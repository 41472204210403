<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="" style="margin-top: -10px">
          <dashboardOverview />
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    dashboardOverview () { return import('@/components/overview') }
  }
}
</script>
